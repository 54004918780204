import {
  Dashboard,
  Settings,
  SystemManagement,
  Seeker,
  CustomerManagement,
  VendorManagement,
  SubAdminManagement,
  ProductManagement,
  WarehouseManagement,
  Taxes,
  Groups,
  GroupTesting,
  Rating,
} from "./Svg";

export const menu = [
  {
    name: "Dashboard",
    path: "/",
    Svg: Dashboard,
    subMenu: [],
    highlight: ["/my-profile", "/change-password"],
    subHighlight: [],
    key: "1",
  },
  {
    name: "Customer Management",
    path: "/customers",
    Svg: CustomerManagement,
    subMenu: [],
    highlight: ["/company/add"],
    subHighlight: [
      "/company/view",
      "/company/edit",
      "/company/change-password",
    ],
    key: "2",
  },

  // {
  //   name: "Sub Admin Management",
  //   Svg: SubAdminManagement,
  //   subMenu: [
  //     {
  //       name: "Sub Admin",
  //       path: "/sub-admins",
  //       highlight: ["/sub-admin/add"],
  //       subHighlight: [
  //         "/sub-admin/view",
  //         "/sub-admin/edit",
  //         "/sub-admin/change-password",
  //       ],
  //       key: "4_1",
  //       subMenu: [],
  //     },
  //     {
  //       name: "Admin Roles",
  //       path: "/admin-roles",
  //       highlight: ["/admin-role/add"],
  //       subHighlight: ["/admin-role/view", "/admin-role/edit"],
  //       key: "4_20",
  //       subMenu: [],
  //     },
  //   ],
  //   highlight: ["/sub-admin/add", "/admin-role/add"],
  //   subHighlight: [
  //     "/sub-admin/view",
  //     "/sub-admin/edit",
  //     "/sub-admin/change-password",
  //     "/admin-role/view",
  //     "/admin-role/edit",
  //   ],
  //   key: "4",
  // },

  // {
  //   name: "Group",
  //   Svg: GroupTesting,
  //   subMenu: [
  //     {
  //       name: "Vendors",
  //       path: "/group/vendors",
  //       highlight: ["/group/vendor/add"],
  //       subHighlight: ["/group/vendor/edit", "/group/vendor/view"],
  //       key: "31_21",
  //       subMenu: [],
  //     },
  //     {
  //       name: "Countries",
  //       path: "/group/countries",
  //       highlight: ["/group/country/add"],
  //       subHighlight: ["/group/country/edit", "/group/country/view"],

  //       key: "31_22",
  //       subMenu: [],
  //     },
  //     {
  //       name: "Customers",
  //       path: "/group/customers",
  //       highlight: ["/group/customer/add"],
  //       subHighlight: ["/group/customer/edit", "/group/customer/view"],
  //       key: "31_23",
  //       subMenu: [],
  //     },

  //     {
  //       name: "Suppliers",
  //       path: "/group/suppliers",
  //       highlight: ["/group/supplier/add"],
  //       subHighlight: ["/group/supplier/edit", "/group/supplier/view"],
  //       key: "31_24",
  //       subMenu: [],
  //     },

  //     {
  //       name: "Products ",
  //       path: "/group/products",
  //       highlight: ["/group/product/add"],
  //       subHighlight: ["/group/product/edit", "/group/product/view"],
  //       key: "31_25",
  //       subMenu: [],
  //     },
  //   ],
  //   highlight: [
  //     "/group/vendor/add",
  //     "/group/country/add",
  //     "/group/customer/add",
  //     "/group/supplier/add",
  //     "/group/product/add",
  //   ],
  //   subHighlight: [
  //     "/group/vendor/edit",
  //     "/group/vendor/view",
  //     "/group/country/edit",
  //     "/group/country/view",
  //     "/group/customer/edit",
  //     "/group/customer/view",
  //     "/group/supplier/edit",
  //     "/group/supplier/view",
  //     "/group/product/edit",
  //     "/group/product/view",
  //   ],
  //   key: "31",
  // },
  {
    name: "Home Page Management",
    Svg: ProductManagement,
    subMenu: [
      {
        name: "Slider",
        path: "/home/section-one",
        highlight: [
          "/home/section-one",
          "/home/section-one",
          "/home/section-one/add",
        ],
        subHighlight: ["/section-one/edit"],
        key: "13_1",
        subMenu: [],
      },
    ],
    highlight: ["/section-one", "/section-one", "/section-one/add"],
    subHighlight: ["/section-one/edit"],
    key: "13",
  },
  {
    name: "Newsletter Subscribers",
    path: "/newsletter-subscribers",
    Svg: CustomerManagement,
    highlight: ["/newsletter-subscribers", "/newsletter-subscribers"],
    subHighlight: ["/newsletter-subscribers"],
    subMenu: [],
  },

  {
    name: "System Management",
    Svg: SystemManagement,
    subMenu: [
      {
        name: "Cms Pages",
        path: "/cms",
        highlight: ["/cms/add"],
        subHighlight: ["/cms/view", "/cms/edit"],
        key: "12_1",
        subMenu: [],
      },
      // {
      //   name: "Seo Pages",
      //   path: "/seo-pages",
      //   highlight: ["/seo-page/add"],
      //   subHighlight: ["/seo-page/edit"],
      //   key: "12_10",
      //   subMenu: [],
      // },
      {
        name: "Email Templates",
        path: "/email-template",
        highlight: ["/email-template/add"],
        subHighlight: ["/email-template/edit"],
        key: "12_20",
        subMenu: [],
      },
      {
        name: "Email Logs",
        path: "/email-logs",
        highlight: [],
        subHighlight: [],
        key: "12_30",
        subMenu: [],
      },
      {
        name: "FAQs",
        path: "/faq",
        highlight: ["/faq/add"],
        subHighlight: ["/faq/edit", "/faq/view"],
        key: "12_40",
        subMenu: [],
      },
      // {
      //   name: "Languages",
      //   path: "/languages",
      //   highlight: ["/language/add"],
      //   subHighlight: [],
      //   key: "12_140",
      //   subMenu: [],
      // },

      {
        name: "Contact Us",
        path: "/contact-us",
        highlight: [],
        subHighlight: [],
        key: "12_200",
        subMenu: [],
      },
    ],
    highlight: [
      "/setting/prefix/Payment",
      "/system-images/add",
      "/country/add",
    ],
    subHighlight: [
      "/system-images/edit",
      "/seo-page/edit",
      "/country/edit",
      "/cities",
      "/taxes",
    ],
    key: "12",
  },
  {
    name: "Masters",
    Svg: SystemManagement,
    subMenu: [
      {
        name: "Title",
        path: "/master/title",
        highlight: ["/master/title/add"],
        subHighlight: ["/master/title/view", "/master/title/edit"],
        key: "12_1",
        subMenu: [],
      },
    ],
    highlight: [],
    subHighlight: [],
  },
  {
    name: "Settings",
    Svg: Settings,
    subMenu: [
      {
        name: "Site Setting",
        path: "/setting/prefix/Site",
        highlight: [],
        subHighlight: [],
        subMenu: [],
        key: "13_2",
      },
      {
        name: "Reading Setting",
        path: "/setting/prefix/Reading",
        key: "13_3",
        highlight: [],
        subHighlight: [],
        subMenu: [],
      },
      {
        name: "Social Setting",
        path: "/setting/prefix/Social",
        key: "13_4",
        highlight: [],
        subHighlight: [],
        subMenu: [],
      },
      {
        name: "Contact Setting",
        path: "/setting/prefix/Contact",
        key: "13_5",
        highlight: [],
        subHighlight: [],
        subMenu: [],
      },
      {
        name: "Footer Setting",
        path: "/home/footer-text",
        key: "13_5",
        highlight: [],
        subHighlight: [],
        subMenu: [],
      },
    ],
    highlight: ["/setting", "/setting/add", "/employee-count/add"],
    subHighlight: ["/setting/edit", "/employee-count/edit"],
    key: "13",
  },
  /* {
    name: "Products",
    Svg: Settings,
    subMenu: [
      {
        name: "Color",
        path: "/color",
        highlight: [],
        subHighlight: [],
        subMenu: [],
        key: "14_2",
      },
      {
        name: "Size",
        path: "/size",
        key: "14_3",
        highlight: [],
        subHighlight: [],
        subMenu: [],
      },
      {
        name: "Brand",
        path: "/brand",
        key: "14_4",
        highlight: [],
        subHighlight: [],
        subMenu: [],
      },
      {
        name: "Features",
        path: "/feature",
        key: "14_4",
        highlight: [],
        subHighlight: [],
        subMenu: [],
      },
      {
        name: "Categories",
        path: "/category",
        key: "14_5",
        highlight: [],
        subHighlight: [],
        subMenu: [],
      },
      {
        name: "Products",
        path: "/product",
        key: "14_5",
        highlight: [],
        subHighlight: [],
        subMenu: [],
      },
    ],
    highlight: [],
    subHighlight: [],
    key: "14",
  }, */
];
