import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
// import { SubTab, SubInput } from "./TabNInput";
// import { DevTool } from "@hookform/devtools";
import { useSelector } from "react-redux";

import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  RenderMultiLangInputFields,
  SubmitButton,
  CKEditorInput,
  ReactSelectInputTwo,
} from "../Form/Form";
import { getLanguageId, getValueInLanguage } from "../../util/helper";

const SubTab = ({ name, index, image }) => {
  return (
    <li className={`nav-item ${index > 0 ? "mr-3" : ""}`}>
      <a
        className={`nav-link ${index === 0 ? "active" : ""}`}
        data-toggle="tab"
        href={`#kt_apps_contacts_view_tab_${index}`}
      >
        <>
          {false && image && (
            <span className="symbol symbol-20 mr-3">
              <img src={`${API.PORT}/${image}`} alt="" />
            </span>
          )}
          <span className="nav-text">{name}</span>
        </>
      </a>
    </li>
  );
};

const SubInput = ({
  index,
  errors,
  register,
  required,
  InputFields,
  code,
  seoInputFields,
  imagePath,
}) => {
  return (
    <div
      className={`tab-pane ${index === 0 ? "active" : ""}`}
      id={`kt_apps_contacts_view_tab_${index}`}
      role="tabpanel"
    >
      <RenderMultiLangInputFields
        InputFields={InputFields}
        errors={errors}
        register={register}
        required={required}
        code={code}
      />

      <h3>SEO Tags</h3>

      <RenderMultiLangInputFields
        InputFields={seoInputFields}
        errors={errors}
        register={register}
        required={required}
        code={code}
      />

      {imagePath && (
        <img
          src={`${API.PORT}/${imagePath}`}
          width={100}
          height={100}
          alt=""
          style={{ cursor: "pointer" }}
          data-fancybox
        />
      )}

      <div className="row"></div>
    </div>
  );
};

const Edit = (props) => {
  const { l1_id, l2_id, id } = props.match.params;
  const ckEditorRef = useRef();
  const [isCkContent, setIsCkContent] = useState(false);

  const [langDataIds, setLangDataIds] = useState({});
  const [image, setImage] = useState(null);
  const [ogImageEn, setOgImageEn] = useState(null);

  const [ogImageAr, setOgImageAr] = useState(null);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);

  const { languages } = useSelector((state) => state.setting);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    clearErrors,
    reset,
    setError,
    control,
  } = useForm();

  const { response: responseGetSection, request: requestGetSection } =
    useRequest();

  const { response, request } = useRequest();
  const { request: colorRequest, response: colorResponse } = useRequest();
  const { request: sizeRequest, response: sizeResponse } = useRequest();

  useEffect(() => {
    requestGetSection("GET", `category/${id}`);

    document.title = "Edit Category - Single Vendor";
  }, []);

  useEffect(() => {
    colorRequest("GET", "color/active");
    sizeRequest("GET", "size/active");
  }, []);

  useEffect(() => {
    if (!colorResponse) return;
    let colorsList = colorResponse.colors.map((color) => {
      return { label: color.name, value: color.id };
    });
    setColors(colorsList);
  }, [colorResponse]);

  useEffect(() => {
    if (!sizeResponse) return;
    let sizesList = sizeResponse.sizes.map((size) => {
      return { label: size.name, value: size.id };
    });
    setSizes(sizesList);
  }, [sizeResponse]);

  useEffect(() => {
    if (responseGetSection) {
      //console.log('edit', responseGetSection);
      const { category: sectionData } = responseGetSection;

      if (!sectionData) {
        history.push("/category-level-2");
        return;
      }

      let languageDatas = sectionData.languageData;
      const resetObj = {};
      const langDataIdsObj = {};
      //resetObj.color_code = sectionData.color_code;
      //resetObj.image = sectionData.image;
      setImage(sectionData.image);
      for (let i = 0; i < languages.length; i++) {
        const id = languages[i].id;
        const code = languages[i].code;
        const currValue = getValueInLanguage(languageDatas, id);

        resetObj[`name-${code}`] = currValue.name;
        resetObj[`title-${code}`] = currValue.title;
        resetObj[`meta_description-${code}`] = currValue.meta_description;
        resetObj[`meta_keywords-${code}`] = currValue.meta_keywords;
        resetObj[`twitter_card-${code}`] = currValue.twitter_card;
        resetObj[`twitter_site-${code}`] = currValue.twitter_site;
        resetObj[`og_url-${code}`] = currValue.og_url;
        resetObj[`og_type-${code}`] = currValue.og_type;
        resetObj[`og_title-${code}`] = currValue.og_title;
        resetObj[`og_description-${code}`] = currValue.og_description;
        resetObj[`meta_chronological-${code}`] = currValue.meta_chronological;
        if (code == "en") setOgImageEn(currValue.og_image);
        if (code == "ar") setOgImageAr(currValue.og_image);
        langDataIdsObj[code] = currValue.id;
      }

      const colorInitalValues = responseGetSection.colors.map((color) => {
        return { label: color.name, value: color.id };
      });
      resetObj.colors = colorInitalValues;

      const sizeInitalValues = responseGetSection.sizes.map((size) => {
        return { label: size.name, value: size.id };
      });
      console.log("ss", sizeInitalValues);
      resetObj.sizes = sizeInitalValues;

      setLangDataIds(langDataIdsObj);
      reset(resetObj);
      setIsCkContent(true);
    }
  }, [responseGetSection, colors, sizes]);

  useEffect(() => {
    if (response) {
      toast.success(response.message);
      history.push(`/category-level-3/${l1_id}/${l2_id}`);
    }
  }, [response]);

  const onSubmitNew = (data) => {
    //console.log("data:::::::::::::", data);
    const formData = new FormData();
    formData.append("id", id);
    if (data["image"] && data["image"][0])
      formData.append("image", data["image"][0]);

    if (data["og_image-en"] && data["og_image-en"][0])
      formData.append("og_image-en", data["og_image-en"][0]);
    if (data["og_image-ar"] && data["og_image-ar"][0])
      formData.append("og_image-ar", data["og_image-ar"][0]);

    formData.append("colors", JSON.stringify(data.colors));
    formData.append("sizes", JSON.stringify(data.sizes));

    //formData.append("slug", data["name-en"]);
    /* const payload = {
      image: data["image"],
      name: data[`name-en`],
      slug: data[`name-en`],
    }; */

    const subData = [];

    for (let i = 0; i < languages.length; i++) {
      const id = languages[i].id;
      const code = languages[i].code;
      const subDataEle = {
        language_id: id,
        lang_code: code,
        id: langDataIds[code],
        name: data[`name-${code}`],
        slug: data[`name-${code}`],
        title: data[`title-${code}`],
        meta_description: data[`meta_description-${code}`],
        meta_keywords: data[`meta_keywords-${code}`],
        twitter_card: data[`twitter_card-${code}`],
        twitter_site: data[`twitter_site-${code}`],
        og_url: data[`og_url-${code}`],
        og_type: data[`og_type-${code}`],
        og_title: data[`og_title-${code}`],
        og_description: data[`og_description-${code}`],
        meta_chronological: data[`meta_chronological-${code}`],
      };

      subData.push(subDataEle);
    }

    //payload.subData = subData;
    formData.append("subData", JSON.stringify(subData));
    //console.log(payload);

    request("PUT", "category", formData);
  };
  const handleonInstanceReady = (editor) => {
    ckEditorRef.current = editor;
  };

  const COMMON = [
    {
      Component: Input,
      label: "Image",
      name: "image",
      registerFields: {
        required: false,
      },
      type: "file",
      inputData: {
        accept: "image/*",
      },
    },
    {
      Component: ReactSelectInputTwo,
      label: "Color",
      name: "colors",
      registerFields: {
        required: true,
      },
      options: colors,
      isMultiple: true,
      control: control,
    },
    {
      Component: ReactSelectInputTwo,
      label: "Size",
      name: "sizes",
      registerFields: {
        required: true,
      },
      options: sizes,
      isMultiple: true,
      control: control,
    },
  ];

  const InputFields = [
    [
      {
        Component: Input,
        label: "Name",
        name: "name",
        registerFields: {
          required: true,
        },
        type: "text",
      },
    ],
  ];

  const seoInputFields = [
    [
      {
        Component: Input,
        label: "Title",
        name: "title",
        isRequired: true,
        registerFields: {
          required: true,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Meta Description",
        name: "meta_description",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Meta Keywords",
        name: "meta_keywords",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Twitter Card",
        name: "twitter_card",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Twitter Site",
        name: "twitter_site",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Og Url",
        name: "og_url",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Og Type",
        name: "og_type",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Og Title",
        name: "og_title",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Og Description",
        name: "og_description",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Meta Chronological",
        name: "meta_chronological",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Og Image",
        name: "og_image",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "file",
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Category Level 3"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/category", name: "Category" },
          { to: `/category-level-2/${l1_id}`, name: "Category Level 2" },
          {
            to: `/category-level-3/${l1_id}/${l2_id}`,
            name: "Category Level 3",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <form onSubmit={handleSubmit(onSubmitNew)}>
            <div className="card card-custom gutter-b">
              <div className="card-body">
                <div className="row">
                  {COMMON.map((Input, index) => (
                    <Input.Component
                      key={index}
                      {...Input}
                      errors={errors}
                      register={register}
                      setValue={setValue}
                    />
                  ))}
                  <img
                    src={`${API.PORT}/${image}`}
                    width={100}
                    height={100}
                    alt=""
                    style={{ cursor: "pointer" }}
                    data-fancybox
                  />
                </div>
              </div>
            </div>

            <div className="card card-custom gutter-b">
              <div className="card-header card-header-tabs-line">
                <div className="card-toolbar">
                  <ul
                    className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                    role="tablist"
                  >
                    {languages.length > 0 &&
                      languages.map((lang, index) => (
                        <SubTab
                          key={index}
                          name={lang.name}
                          index={index}
                          image={lang?.image}
                        />
                      ))}
                  </ul>
                </div>
              </div>

              <div className="card-body px-0">
                <div className="tab-content px-10">
                  {languages.length > 0 &&
                    languages.map((lang, index) => (
                      <>
                        <SubInput
                          key={index}
                          index={index}
                          errors={errors}
                          register={register}
                          //   required={lang.default}
                          required={false}
                          InputFields={InputFields}
                          code={lang.code}
                          seoInputFields={seoInputFields}
                          imagePath={lang.code === "en" ? ogImageEn : ogImageAr}
                        />
                      </>
                    ))}
                </div>
                <button
                  onClick={handleSubmit(onSubmitNew)}
                  style={{ display: "none" }}
                ></button>

                <SubmitButton
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmitNew}
                  name="Update"
                  pxClass="px-10"
                />

                <div className="row"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default Edit;
