import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput, SearchSubmitButton } from "../Form/Form";
import { addOneToDate, convertDate } from "../../util/fn";

const OBJ_TABLE = {
  "updated on": "updated_at",
  oldStock: "old_stock",
  newStock: "new_stock",
  color: "color",
  size: "size",
  actionType: "action_type",
};

const StockHistory = (props) => {
  const { id } = props.match.params;
  const [stockhistory, setStockHistory] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "updated on",
    order: "desc",
  });

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);
  const { roleId, permission } = useSelector((state) => state.auth);
  const convertData = {
    update_by_admin: "Update By Admin",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
  } = useForm();

  const { request, response } = useRequest();
  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();

  const searchQueryHandler = (
    page,
    per_page,
    sortBy,
    order,
    dateFrom = "1970-01-01",
    dateTo
  ) => {
    if (sortBy.length > 0) {
      if (sortBy == "updated on") {
        sortBy = "updated_at";
      }
      if (sortBy == "oldStock") {
        sortBy = "old_stock";
      }
      if (sortBy == "newStock") {
        sortBy = "new_stock";
      }
      if (sortBy == "actionType") {
        sortBy = "action_type";
      }
    } else {
      sortBy = "updated_at";
    }

    order = order.length > 0 ? order : "desc";

    dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

    dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());

    return `product/stock-history/${id}/?page=${page}&per_page=${per_page}&sort_by=${sortBy}&order=${order}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
  };

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Stock History- Single Vendor";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      let stockData = response.stocks;
      stockData = stockData.map((stock) => {
        return {
          ...stock,
          action_type: convertData[stock.action_type],
        };
      });
      setStockHistory(stockData);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  /* useEffect(() => {
    if (responseChangeStatus) {
      const { id, newStatus } = responseChangeStatus;

      const oldBrands = [...brands];
      const indexToChange = oldBrands.findIndex((brand) => brand.id == id);
      oldBrands[indexToChange].isActive = newStatus ? true : false;
      oldBrands[indexToChange].is_active = newStatus;
      toast.success(responseChangeStatus.message);
      setBrands(oldBrands);
    }
  }, [responseChangeStatus]); */

  /*  useEffect(() => {
    if (responseDelete) {
      const { id } = responseDelete;
      let newBrands = [...brands];
      newBrands = newBrands.filter((brand) => brand.id != id);
      setBrands(newBrands);

      setTotalDocuments(totalDocuments - 1);
      // const pageCount = Math.ceil((totalDocuments) / perPage)

      // if(pageCount == page){
      //   const { question } = getValues();
      //   const newPage = page - 1;
      //   setPage(newPage);
      //   request(
      //     "GET",
      //     searchQueryHandler(
      //       newPage,
      //       perPage,
      //       currentSort.sortBy,
      //       currentSort.order,
      //       question
      //     )
      //   );
      // }
      toast.success("Brand has been deleted successfully.");
    }
  }, [responseDelete]); */

  const fetchMoreData = ({ selected }) => {
    setStockHistory([]);
    //brand which is enter on search input
    const { brand } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        brand
      )
    );
  };

  /* const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this brand?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", "brand", { id });
      } else if (result.isDismissed) {
      }
    });
  }; */

  const onSearchHandler = (data) => {
    const { dateFrom, dateTo } = getValues();
    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        dateFrom,
        dateTo
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("dateFrom");
    resetField("dateTo");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const { dateFrom, dateTo } = getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        dateFrom,
        dateTo
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const { dateFrom, dateTo } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(page, perPage, sortBy, newOrder, dateFrom, dateTo)
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(page, perPage, sortBy, "desc", dateFrom, dateTo)
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  /* const changeStatusHandler = (id) => {
    const brandNew = brands.find((brand) => brand.id == id);

    const status = brandNew.is_active == 1 ? 0 : 1;

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("PUT", "brand/status", {
          id,
          status,
        });
      } else if (result.isDismissed) {
      }
    });
  }; */

  //search input field
  const InputFields = [
    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Product Stock History"
        links={[
          { to: "/product", name: "Products" },
          { to: "/", name: "Dashboard" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    {/* <button className="btn btn-primary">Export</button> */}
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper faq-table">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={stockhistory}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      /* links={[
                        {
                          isLink: false,
                          name: "Deactivate",
                          click: changeStatusHandler,
                          title: "Click To Activate",
                          key: "12_42",
                        },
                        {
                          isLink: false,
                          name: "Activate",
                          click: changeStatusHandler,
                          title: "Click To Deactivate",
                          key: "12_42",
                        },
                        {
                          isLink: true,
                          to: "/brand/edit",
                          name: "Edit",
                          extraData: true,
                          key: "12_43",
                        },
                        {
                          isLink: false,
                          name: "Delete",
                          click: deleteHandler,
                          key: "12_44",
                        },
                      ]} */
                      onlyDate={{
                        updated_at: "dateTimeWithSuffix",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                      dontShowSort={["question", "answer"]}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={stockhistory.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockHistory;
