import React, { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
// import { SubTab, SubInput } from "./TabNInput";
// import { DevTool } from "@hookform/devtools";
import { useSelector } from "react-redux";

import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  RenderMultiLangInputFields,
  SubmitButton,
  CKEditorInput,
  SelectInput,
  Textarea,
  ReactSelectInputTwo,
} from "../Form/Form";
import "./product.css";
import ImageUploader from "./components/ImageUploader";
import { getValueInLanguage } from "../../util/helper";
import EditImageUploader from "./components/EditImageUploader";

const SubTab = ({ id, name, index, image }) => {
  return (
    <li className={`nav-item ${index > 0 ? "mr-3" : ""}`}>
      <a
        className={`nav-link ${index === 0 ? "active" : ""}`}
        data-toggle="tab"
        href={`#${id}_${index}`}
      >
        <>
          {false && image && (
            <span className="symbol symbol-20 mr-3">
              <img src={`${API.PORT}/${image}`} alt="" />
            </span>
          )}
          <span className="nav-text">{name}</span>
        </>
      </a>
    </li>
  );
};

const SubInput = ({
  id,
  index,
  errors,
  register,
  required,
  InputFields,
  code,
}) => {
  return (
    <div
      className={`tab-pane ${index === 0 ? "active" : ""}`}
      id={`${id}_${index}`}
      role="tabpanel"
    >
      <RenderMultiLangInputFields
        InputFields={InputFields}
        errors={errors}
        register={register}
        required={required}
        code={code}
      />

      <div className="row"></div>
    </div>
  );
};

const Edit = (props) => {
  const { id } = props.match.params;
  const ckEditorRef = useRef();
  //const [isCkContent, setIsCkContent] = useState(false);

  const [langDataIds, setLangDataIds] = useState({});

  const { languages } = useSelector((state) => state.setting);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    clearErrors,
    setError,
    control,
    unregister,
    reset,
    resetField,
  } = useForm({
    defaultValues: {
      variants: [],
    },
  });

  const [parentCategoryInput, setParentCategoryInput] = useState([]);

  const [categoryL2Input, setCategoryL2Input] = useState(null);
  const [categoryL3Input, setCategoryL3Input] = useState(null);
  const [colorsList, setColorsList] = useState([]);
  const [sizesList, setSizesList] = useState([]);
  const [featuresList, setFeaturesList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [coverImage, setCoverImage] = useState();
  const [images, setImages] = useState([]);
  const [showAddVarient, setShowAddVarient] = useState(false);
  const [inputVarients, setInputVariants] = useState([]);
  const [sizeImage, setSizeImage] = useState();
  const [editor, setEditor] = useState({
    item_description: false,
    service_description: false,
    exchange_and_refund_description: false,
  });

  //Media

  const { request: getProductData, response: productDataResponse } =
    useRequest();

  const { response, request } = useRequest();
  const { request: getParentCategory, response: parentCategoryResponse } =
    useRequest();
  const { request: getCategoryLevel2, response: categoryLevel2Response } =
    useRequest();
  const { request: getCategoryLevel3, response: categoryLevel3Response } =
    useRequest();
  const { request: getActiveColors, response: activeColorsResponse } =
    useRequest();
  const { request: getActiveSizes, response: activeSizesResponse } =
    useRequest();
  const { request: getActiveFeatures, response: activeFeaturesResponse } =
    useRequest();
  const { request: getActiveBrands, response: activeBrandsResponse } =
    useRequest();

  useEffect(() => {
    document.title = "Add-Color - Single Vendor";
    getParentCategory("GET", `category/activeCategories?parent_id=${0}`);
    getActiveColors("GET", `color/active`);
    getActiveSizes("GET", `size/active`);
    getActiveFeatures("GET", `feature/active`);
    getProductData("GET", `product/details/${id}`);
    getActiveBrands("GET", `brand/active`);
  }, []);

  useEffect(() => {
    if (!activeColorsResponse) return;
    let colors = activeColorsResponse.colors.map((color) => {
      return { label: color.name, value: color.id };
    });
    setColorsList(colors);
  }, [activeColorsResponse]);

  useEffect(() => {
    if (!activeSizesResponse) return;
    let sizes = activeSizesResponse.sizes.map((size) => {
      return { label: size.name, value: size.id };
    });
    setSizesList(sizes);
  }, [activeSizesResponse]);

  useEffect(() => {
    if (!activeFeaturesResponse) return;
    let features = activeFeaturesResponse.features.map((feature) => {
      return { label: feature.name, value: feature.id };
    });
    setFeaturesList(features);
  }, [activeFeaturesResponse]);

  useEffect(() => {
    if (!activeBrandsResponse) return;

    setBrandList(activeBrandsResponse.brands);
  }, [activeBrandsResponse]);

  useEffect(() => {
    if (!parentCategoryResponse) return;
    //console.log("in parent list");
    setParentCategoryInput(parentCategoryResponse.categories);
  }, [parentCategoryResponse]);

  useEffect(() => {
    if (response) {
      toast.success(response.message);
      history.push("/product");
    }
  }, [response]);

  //Media

  const onSubmitNew = (data) => {
    console.log("data:::::::::::::", data);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("parent_category", data.parent_category);
    formData.append("category_level_2", data.category_level_2);
    formData.append("category_level_3", data.category_level_3);
    formData.append("brand_id", data.brand_id);
    formData.append("colors", JSON.stringify(data.colors));
    formData.append("features", JSON.stringify(data.features));
    formData.append("sizes", JSON.stringify(data.sizes));
    formData.append("price", data.price);
    formData.append("discount_price", data.discount_price || 0);
    formData.append("stock_qty", data.stock_qty);
    // formData.append(
    //   "slug",
    //   `${data[`name-en`].replace(/ /g, "_")}-${Date.now()}`
    // );
    formData.append("name", data["name-en"]);
    if (images.length === 0) {
      return toast.error("please upload image");
    }
    formData.append("cover_image", coverImage);

    if (data["size_image"] && data["size_image"][0])
      formData.append("size_image", data["size_image"][0]);

    if (!data.variants.length) return toast.error("Please Add variants");

    if (data.variants)
      formData.append("variants", JSON.stringify(data.variants));
    const informationSubData = [];
    const descriptionSubData = [];

    for (let i = 0; i < languages.length; i++) {
      const id = languages[i].id;
      const code = languages[i].code;
      const slug = `${data[`name-${code}`].replace(/ /g, "_")}-${Date.now()}`;
      /* const infoDataEle = {
        language_id: id,
        lang_code: code,
        name: data[`name-${code}`],
        slug: slug,
      }; */
      const descDataEle = {
        language_id: id,
        lang_code: code,
        name: data[`name-${code}`],
        small_description: data[`small_description-${code}`],
        item_description: data[`item_description-${code}`],
        service_description: data[`service_description-${code}`],
        exchange_and_refund_description:
          data[`exchange_and_refund_description-${code}`],
        id: langDataIds[code],
      };

      //informationSubData.push(infoDataEle);
      descriptionSubData.push(descDataEle);
    }
    formData.append("informationSubData", JSON.stringify(informationSubData));
    formData.append("descriptionSubData", JSON.stringify(descriptionSubData));

    request("PUT", "product", formData);
  };

  useEffect(() => {
    if (productDataResponse) {
      //console.log("in main edit");
      //console.log('edit', productDataResponse);
      const { product } = productDataResponse;

      if (!product) {
        history.push("/product");
        return;
      }

      getCategoryLevel2(
        "GET",
        `category/activeCategories?parent_id=${product.parent_category}`
      );
      getCategoryLevel3(
        "GET",
        `category/activeCategories?parent_id=${product.category_level_2}`
      );

      let languageDatas = product.languageData;
      const resetObj = {};
      const langDataIdsObj = {};
      //resetObj.color_code = product.color_code;
      //resetObj.image = product.image;
      setCoverImage(product.image);
      setSizeImage(product?.size_image);
      setImages([
        product.image,
        ...productDataResponse?.images?.map((img) => img.image),
      ]);
      for (let i = 0; i < languages.length; i++) {
        const id = languages[i].id;
        const code = languages[i].code;
        const currValue = getValueInLanguage(languageDatas, id);

        resetObj[`name-${code}`] = currValue.name;
        //resetObj[`title-${code}`] = currValue.title;
        resetObj[`small_description-${code}`] = currValue.small_description;
        resetObj[`item_description-${code}`] = currValue.item_description;
        resetObj[`service_description-${code}`] = currValue.service_description;
        resetObj[`exchange_and_refund_description-${code}`] =
          currValue.exchange_and_refund_description;

        langDataIdsObj[code] = currValue.id;
      }

      resetObj.price = product.price;
      resetObj.discount_price = product.discount_price;
      resetObj.stock_qty = product.stock_qty;

      resetObj.parent_category = product.parent_category;

      resetObj.category_level_2 = product.category_level_2;

      resetObj.category_level_3 = product.category_level_3;
      resetObj.brand_id = product.brand_id;

      const colorInitalValues = productDataResponse.colors.map((color) => {
        return { label: color.name, value: color.color_id };
      });
      resetObj.colors = colorInitalValues;

      const sizeInitalValues = productDataResponse.sizes.map((size) => {
        return { label: size.name, value: size.size_id };
      });

      resetObj.sizes = sizeInitalValues;

      const featureInitalValues = productDataResponse.features.map(
        (feature) => {
          return { label: feature.name, value: feature.features_id };
        }
      );

      resetObj.features = featureInitalValues;

      //console.log("ss", resetObj);
      let imageList = [product.image];

      productDataResponse.images.forEach((image) => {
        imageList.push(image.image);
      });

      let variants = productDataResponse.variants;
      //console.log(variants);
      const inputVarientList = [];
      if (variants && variants.length) {
        variants.forEach((variant) => {
          inputVarientList.push({
            label: `${variant.color}-${variant.size}`,
            colorId: variant.color_id,
            sizeId: variant.size_id,
            price: variant.price,
            discount_price: variant.discount_price,
            stock_qty: variant.stock_qty,
          });
        });
        setShowAddVarient(true);
        setInputVariants(inputVarientList);
        resetObj.variants = inputVarientList;
      }
      //console.log(imageList);

      setImages(imageList);
      setLangDataIds(langDataIdsObj);
      setEditor({
        item_description: !!resetObj["item_description-en"],
        service_description: !!resetObj["service_description-en"],
        exchange_and_refund_description:
          !!resetObj["exchange_and_refund_description-en"],
      });
      reset(resetObj);
      //console.log("initial", getValues());
      //setIsCkContent(true);
    }
  }, [productDataResponse]);

  function openTab(evt, tabName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " active";
  }

  const changeParentCategory = (value) => {
    setValue("category_level_2", "");
    setValue("category_level_3", "");
    setCategoryL2Input([]);
    setCategoryL3Input([]);
    getCategoryLevel2("GET", `category/activeCategories?parent_id=${value}`);
    //console.log("change parent", getValues());
  };

  const changeCategoryL2 = (value) => {
    setValue("category_level_3", "");
    setCategoryL3Input([]);
    getCategoryLevel3("GET", `category/activeCategories?parent_id=${value}`);
  };

  useEffect(() => {
    if (!categoryLevel2Response) return;
    //console.log(categoryLevel2Response);
    setCategoryL2Input(categoryLevel2Response.categories);

    //console.log("in effect", getValues());
  }, [categoryLevel2Response]);

  useEffect(() => {
    if (!categoryLevel3Response) return;
    //console.log(categoryLevel2Response);
    setCategoryL3Input(categoryLevel3Response.categories);
  }, [categoryLevel3Response]);

  const ProductInformationInputFields = [
    [
      {
        Component: Input,
        label: "Name",
        name: "name",
        registerFields: {
          required: true,
        },
        type: "text",
      },
    ],
  ];

  const ProductCommonInformationInputField = [
    {
      Component: SelectInput,
      label: "Parent Category",
      name: "parent_category",
      registerFields: {
        required: true,
      },
      type: "SelectInput",
      onChange: changeParentCategory,
      children: (
        <>
          <option value={""}>Select Parent Category</option>,
          {parentCategoryInput.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      Component: SelectInput,
      label: "Category Level 2",
      name: "category_level_2",
      registerFields: {
        required: true,
      },
      type: "SelectInput",
      onChange: changeCategoryL2,
      children: (
        <>
          <option value={""}>Select Category</option>,
          {categoryL2Input?.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      Component: SelectInput,
      label: "Category Level 3",
      name: "category_level_3",
      registerFields: {
        required: true,
      },
      type: "SelectInput",
      children: (
        <>
          <option value={""}>Select Category</option>,
          {categoryL3Input?.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      Component: SelectInput,
      label: "Brand",
      name: "brand_id",
      registerFields: {
        required: true,
      },
      type: "SelectInput",
      children: (
        <>
          <option value={""}>Select Brand</option>,
          {brandList.map((brand) => (
            <option value={brand.id}>{brand.name}</option>
          ))}
        </>
      ),
    },
    {
      Component: Input,
      label: "Size image",
      name: "size_image",
      registerFields: {
        required: false,
      },
      isRequired: false,
      inputData: {
        accept: "image/*",
      },
      type: "file",
    },
  ];

  const PriceInputFields = [
    {
      Component: Input,
      label: "Price",
      name: "price",
      registerFields: {
        required: true,
      },
      type: "number",
    },
    {
      Component: Input,
      label: "Discount Price",
      name: "discount_price",
      registerFields: {
        required: false,
      },
      type: "number",
    },
    {
      Component: Input,
      label: "Stock Quantity",
      name: "stock_qty",
      registerFields: {
        required: true,
      },
      type: "number",
    },
  ];

  const DescriptionInputFields = [
    [
      {
        Component: Textarea,
        label: "Small Description",
        name: "small_description",
        registerFields: {
          required: true,
        },
        type: "text",
        colClass: "col-xl-12",
      },
      {
        Component: CKEditorInput,
        colClass: "col-xl-12",
        label: "Item Description",
        name: "item_description",
        registerFields: {
          required: false,
        },
        isRequired: false,
        getValues,
        setValue,
        trigger,
        inputData: {
          onInstanceReady: (editor) => {
            ckEditorRef.current = editor;
          },
        },
        clearErrors,
        isEdit: editor.item_description,
        setError,
      },
      {
        Component: CKEditorInput,
        colClass: "col-xl-12",
        label: "Service Description",
        name: "service_description",
        registerFields: {
          required: false,
        },
        isRequired: false,
        getValues,
        setValue,
        trigger,
        inputData: {
          onInstanceReady: (editor) => {
            ckEditorRef.current = editor;
          },
        },
        clearErrors,
        isEdit: editor.service_description,
        setError,
      },
      {
        Component: CKEditorInput,
        colClass: "col-xl-12",
        label: "Exchange and Refund Description",
        name: "exchange_and_refund_description",
        registerFields: {
          required: false,
        },
        isRequired: false,
        getValues,
        setValue,
        trigger,
        inputData: {
          onInstanceReady: (editor) => {
            ckEditorRef.current = editor;
          },
        },
        clearErrors,
        isEdit: editor.exchange_and_refund_description,
        setError,
      },
    ],
  ];

  const FeaturesInputField = [
    {
      Component: ReactSelectInputTwo,
      label: "Feature",
      name: "features",
      registerFields: {
        required: true,
      },
      options: featuresList,
      isMultiple: true,
      control: control,
    },
  ];

  const VarientInputField = [
    {
      Component: ReactSelectInputTwo,
      label: "Color",
      name: "colors",
      registerFields: {
        required: true,
      },
      options: colorsList,
      isMultiple: true,
      control: control,
    },
    {
      Component: ReactSelectInputTwo,
      label: "Size",
      name: "sizes",
      registerFields: {
        required: true,
      },
      options: sizesList,
      isMultiple: true,
      control: control,
    },
  ];

  const getVariantItem = (color_id, size_id) => {
    let variants = productDataResponse.variants;
    let variantItem = variants.find(
      (variant) => variant.color_id == color_id && variant.size_id == size_id
    );
    return variantItem;
  };

  const makeVarient = () => {
    const selectedColors = getValues("colors");
    const selectedSizes = getValues("sizes");
    //console.log(selectedColors);
    //console.log(selectedSizes);
    //if (!selectedColors || !selectedColors.length) return;
    //if (!selectedSizes || !selectedSizes.length) return;
    //unregister("variants");

    const inputVarientList = [];

    selectedColors.forEach((color) => {
      selectedSizes.forEach((size) => {
        let item = {
          label: `${color.label}-${size.label}`,
          colorId: color.value,
          sizeId: size.value,
        };
        const getData = getVariantItem(item.colorId, item.sizeId);
        inputVarientList.push({
          ...item,
          price: getData?.price || 0,
          discount_price: getData?.discount_price || 0,
          stock_qty: getData?.stock_qty || 0,
        });
      });
    });
    //console.log("ii", inputVarientList);
    //let formData = { ...getValues() };
    //formData.variants = inputVarientList;
    //reset(formData);
    setValue("variants", inputVarientList);
    //console.log("form", getValues());
    setInputVariants(inputVarientList);
    setShowAddVarient(inputVarientList.length ? true : false);
  };

  useEffect(() => {
    document.getElementById("defaultOpen").click();
  }, []);

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit-Product"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/product", name: "Product" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <form onSubmit={handleSubmit(onSubmitNew)}>
            <div className="card card-custom gutter-b">
              <div className="card-body flex">
                {/* <div className="row">
                  {COMMON.map((Input, index) => (
                    <Input.Component
                      key={index}
                      {...Input}
                      errors={errors}
                      register={register}
                      setValue={setValue}
                    />
                  ))}
                </div> */}

                <div className="tab">
                  <button
                    type="button"
                    className="tablinks"
                    onClick={(e) => openTab(e, "productInformation")}
                    id="defaultOpen"
                  >
                    Product Information
                  </button>
                  <button
                    className="tablinks"
                    type="button"
                    onClick={(e) => openTab(e, "prices")}
                  >
                    Prices
                  </button>
                  <button
                    className="tablinks"
                    type="button"
                    onClick={(e) => openTab(e, "productImages")}
                  >
                    Product Images
                  </button>
                  <button
                    className="tablinks"
                    type="button"
                    onClick={(e) => openTab(e, "productDescriptions")}
                  >
                    Product Descriptions
                  </button>
                  <button
                    className="tablinks"
                    type="button"
                    onClick={(e) => openTab(e, "productFeature")}
                  >
                    Product Features
                  </button>
                  <button
                    className="tablinks"
                    type="button"
                    onClick={(e) => openTab(e, "productVarient")}
                  >
                    Product Varient
                  </button>
                </div>

                <div id="productInformation" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">
                    Product Information
                  </h3>
                  <div className="card-header-tabs-line">
                    <div className="card-toolbar">
                      <ul
                        className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                        role="tablist"
                      >
                        {languages.length > 0 &&
                          languages.map((lang, index) => (
                            <SubTab
                              id="productInformation"
                              key={index}
                              name={lang.name}
                              index={index}
                              image={lang?.image}
                            />
                          ))}
                      </ul>
                    </div>
                  </div>
                  <div className="card-body px-0">
                    <div className="tab-content px-0">
                      {languages.length > 0 &&
                        languages.map((lang, index) => (
                          <>
                            <SubInput
                              id="productInformation"
                              key={index}
                              index={index}
                              errors={errors}
                              register={register}
                              required={true}
                              InputFields={ProductInformationInputFields}
                              code={lang.code}
                            />
                          </>
                        ))}
                    </div>
                    <button
                      onClick={handleSubmit(onSubmitNew)}
                      style={{ display: "none" }}
                    ></button>

                    <div className="row"></div>
                  </div>
                  <div className="row">
                    {categoryL2Input &&
                      categoryL3Input &&
                      ProductCommonInformationInputField.map((Input, index) => (
                        <Input.Component
                          key={index}
                          {...Input}
                          errors={errors}
                          register={register}
                          setValue={setValue}
                        />
                      ))}
                  </div>
                  {sizeImage && (
                    <img
                      src={`${API.PORT}/${sizeImage}`}
                      width={100}
                      height={100}
                      alt=""
                      style={{ cursor: "pointer" }}
                      data-fancybox
                    />
                  )}
                </div>

                <div id="prices" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">
                    Price Information
                  </h3>
                  <div className="row">
                    {PriceInputFields.map((Input, index) => (
                      <Input.Component
                        key={index}
                        {...Input}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                      />
                    ))}
                  </div>
                </div>

                <div id="productImages" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">Images</h3>
                  <EditImageUploader
                    productId={id}
                    images={images}
                    setImages={setImages}
                    coverImage={coverImage}
                    setCoverImage={setCoverImage}
                  />
                </div>
                <div id="productDescriptions" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">
                    Product Description
                  </h3>
                  <div className="card-header-tabs-line">
                    <div className="card-toolbar">
                      <ul
                        className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                        role="tablist"
                      >
                        {languages.length > 0 &&
                          languages.map((lang, index) => (
                            <SubTab
                              id="productDescriptions"
                              key={index}
                              name={lang.name}
                              index={index}
                              image={lang?.image}
                            />
                          ))}
                      </ul>
                    </div>
                  </div>
                  <div className="card-body px-0">
                    <div className="tab-content px-10">
                      {languages.length > 0 &&
                        languages.map((lang, index) => (
                          <>
                            <SubInput
                              id="productDescriptions"
                              key={index}
                              index={index}
                              errors={errors}
                              register={register}
                              required={true}
                              InputFields={DescriptionInputFields}
                              code={lang.code}
                            />
                          </>
                        ))}
                    </div>
                    <button
                      onClick={handleSubmit(onSubmitNew)}
                      style={{ display: "none" }}
                    ></button>

                    <div className="row"></div>
                  </div>
                </div>
                <div id="productFeature" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">
                    Product Features
                  </h3>
                  <div className="row">
                    {FeaturesInputField.map((Input, index) => (
                      <Input.Component
                        key={index}
                        {...Input}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                      />
                    ))}
                  </div>
                </div>
                <div id="productVarient" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">
                    Product Varients
                  </h3>
                  <div className="row">
                    {VarientInputField.map((Input, index) => (
                      <Input.Component
                        key={index}
                        {...Input}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                      />
                    ))}
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    id="variantbuttons"
                    onClick={makeVarient}
                  >
                    Make Variant
                  </button>
                  {showAddVarient && (
                    <div class="col-lg-12">
                      <div class="dataTables_wrapper">
                        <div class="table-responsive" id="varianttable">
                          <table class="table dataTable table-head-custom table-head-bg table-borderless table-vertical-center">
                            <thead>
                              <tr class="text-uppercase">
                                <th
                                  style={{ minWidth: "80px", maxWidth: "80px" }}
                                >
                                  <a href="javascript:void(0);">Variant</a>
                                </th>
                                <th
                                  style={{ minWidth: "80px", maxWidth: "80px" }}
                                >
                                  <a href="javascript:void(0);">Price</a>
                                </th>
                                <th
                                  style={{ minWidth: "80px", maxWidth: "80px" }}
                                >
                                  <a href="javascript:void(0);">
                                    Discount Price
                                  </a>
                                </th>
                                <th
                                  style={{ minWidth: "80px", maxWidth: "80px" }}
                                >
                                  <a href="javascript:void(0);">Stock QTY</a>
                                </th>
                                {/* <th style="width:40%;">
                                  <a href="javascript:void(0);">Images</a>
                                </th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {inputVarients.map((variant, index) => (
                                <tr>
                                  <td>
                                    {" "}
                                    <input
                                      {...register(`variants.${index}.colorId`)}
                                      value={variant.colorId}
                                      type="hidden"
                                    />{" "}
                                    <input
                                      {...register(`variants.${index}.sizeId`)}
                                      value={variant.sizeId}
                                      type="hidden"
                                    />{" "}
                                    {variant.label}
                                  </td>
                                  <td>
                                    <input
                                      class="form-control form-control-solid form-control-lg"
                                      {...register(`variants.${index}.price`)}
                                      type="number"
                                      defaultValue={variant.price}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      class="form-control form-control-solid form-control-lg"
                                      {...register(
                                        `variants.${index}.discount_price`
                                      )}
                                      type="number"
                                      defaultValue={variant.discount_price}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      class="form-control form-control-solid form-control-lg variant_stock_quantity"
                                      {...register(
                                        `variants.${index}.stock_qty`
                                      )}
                                      defaultValue={variant.stock_qty}
                                      type="number"
                                    />
                                  </td>
                                  <td>
                                    <div
                                      class="row variant_parent"
                                      style={{ flexWrap: "nowrap" }}
                                    ></div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <SubmitButton
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmitNew}
                  name="Update"
                  pxClass="px-10"
                />
              </div>
            </div>

            {/* <div className="card card-custom gutter-b">
              <div className="card-header card-header-tabs-line">
                <div className="card-toolbar">
                  <ul
                    className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                    role="tablist"
                  >
                    {languages.length > 0 &&
                      languages.map((lang, index) => (
                        <SubTab
                          key={index}
                          name={lang.name}
                          index={index}
                          image={lang?.image}
                        />
                      ))}
                  </ul>
                </div>
              </div>

              <div className="card-body px-0">
                <div className="tab-content px-10">
                  {languages.length > 0 &&
                    languages.map((lang, index) => (
                      <>
                        <SubInput
                          key={index}
                          index={index}
                          errors={errors}
                          register={register}
                          required={true}
                          InputFields={InputFields}
                          code={lang.code}
                        />
                      </>
                    ))}
                </div>
                <button
                  onClick={handleSubmit(onSubmitNew)}
                  style={{ display: "none" }}
                ></button>

                <SubmitButton
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmitNew}
                  name="Add"
                  pxClass="px-10"
                />

                <div className="row"></div>
              </div>
            </div> */}
          </form>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default Edit;
