import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
// import { SubTab, SubInput } from "./TabNInput";
// import { DevTool } from "@hookform/devtools";
import { useSelector } from "react-redux";

import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  RenderMultiLangInputFields,
  SubmitButton,
  CKEditorInput,
} from "../Form/Form";
import { getLanguageId, getValueInLanguage } from "../../util/helper";

const SubTab = ({ name, index, image }) => {
  return (
    <li className={`nav-item ${index > 0 ? "mr-3" : ""}`}>
      <a
        className={`nav-link ${index === 0 ? "active" : ""}`}
        data-toggle="tab"
        href={`#kt_apps_contacts_view_tab_${index}`}
      >
        <>
          {false && image && (
            <span className="symbol symbol-20 mr-3">
              <img src={`${API.PORT}/${image}`} alt="" />
            </span>
          )}
          <span className="nav-text">{name}</span>
        </>
      </a>
    </li>
  );
};

const SubInput = ({ index, errors, register, required, InputFields, code }) => {
  return (
    <div
      className={`tab-pane ${index === 0 ? "active" : ""}`}
      id={`kt_apps_contacts_view_tab_${index}`}
      role="tabpanel"
    >
      <RenderMultiLangInputFields
        InputFields={InputFields}
        errors={errors}
        register={register}
        required={required}
        code={code}
      />

      <div className="row"></div>
    </div>
  );
};

const Edit = (props) => {
  const { id } = props.match.params;
  const ckEditorRef = useRef();
  const [isCkContent, setIsCkContent] = useState(false);

  const [langDataIds, setLangDataIds] = useState({});
  //const [images, setImages] = useState(null);

  const { languages } = useSelector((state) => state.setting);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    clearErrors,
    reset,
    setError,
  } = useForm();

  const { response: responseGetSection, request: requestGetSection } =
    useRequest();

  const { response, request } = useRequest();

  useEffect(() => {
    requestGetSection("GET", `color/${id}`);

    document.title = "Edit Color - Single Vendor";
  }, []);

  useEffect(() => {
    if (responseGetSection) {
      const { color: sectionData } = responseGetSection;

      if (!sectionData) {
        history.push("/color");
        return;
      }

      let languageDatas = sectionData.languageData;
      const resetObj = {};
      const langDataIdsObj = {};
      resetObj.color_code = sectionData.color_code;
      resetObj.name = sectionData.name;

      for (let i = 0; i < languages.length; i++) {
        const id = languages[i].id;
        const code = languages[i].code;
        const currValue = getValueInLanguage(languageDatas, id);
        resetObj[`name-${code}`] = currValue.name;
        langDataIdsObj[code] = currValue.id;
      }

      console.log(resetObj);
      setLangDataIds(langDataIdsObj);
      reset(resetObj);
      setIsCkContent(true);
    }
  }, [responseGetSection]);

  useEffect(() => {
    if (response) {
      toast.success(response.message);
      history.push("/color");
    }
  }, [response]);

  const onSubmitNew = (data) => {
    const payload = {
      id: id,
      name: data[`name-en`],
      color_code: data.color_code,
    };

    const subData = [];

    for (let i = 0; i < languages.length; i++) {
      const id = languages[i].id;
      const code = languages[i].code;
      subData.push({
        name: data[`name-${code}`],
        id: langDataIds[code],
      });
    }

    payload.subData = subData;
    request("PUT", "color", payload);
  };
  const handleonInstanceReady = (editor) => {
    ckEditorRef.current = editor;
  };

  const COMMON = [
    {
      Component: Input,
      label: "Color Code",
      name: "color_code",
      registerFields: {
        required: true,
      },
      type: "color",
    },
  ];

  const InputFields = [
    [
      {
        Component: Input,
        label: "Name",
        name: "name",
        registerFields: {
          required: true,
        },
        type: "text",
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Color"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/color", name: "Color" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <form onSubmit={handleSubmit(onSubmitNew)}>
            <div className="card card-custom gutter-b">
              <div className="card-body">
                <div className="row">
                  {COMMON.map((Input, index) => (
                    <Input.Component
                      key={index}
                      {...Input}
                      errors={errors}
                      register={register}
                      setValue={setValue}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div className="card card-custom gutter-b">
              <div className="card-header card-header-tabs-line">
                <div className="card-toolbar">
                  <ul
                    className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                    role="tablist"
                  >
                    {languages.length > 0 &&
                      languages.map((lang, index) => (
                        <SubTab
                          key={index}
                          name={lang.name}
                          index={index}
                          image={lang?.image}
                        />
                      ))}
                  </ul>
                </div>
              </div>

              <div className="card-body px-0">
                <div className="tab-content px-10">
                  {languages.length > 0 &&
                    languages.map((lang, index) => (
                      <>
                        <SubInput
                          key={index}
                          index={index}
                          errors={errors}
                          register={register}
                          //   required={lang.default}
                          required={false}
                          InputFields={InputFields}
                          code={lang.code}
                        />
                      </>
                    ))}
                </div>
                <button
                  onClick={handleSubmit(onSubmitNew)}
                  style={{ display: "none" }}
                ></button>

                <SubmitButton
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmitNew}
                  name="Update"
                  pxClass="px-10"
                />

                <div className="row"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default Edit;
