import React, { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
// import { SubTab, SubInput } from "./TabNInput";
// import { DevTool } from "@hookform/devtools";
import { useSelector } from "react-redux";

import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  RenderMultiLangInputFields,
  SubmitButton,
  CKEditorInput,
  SelectInput,
  Textarea,
  ReactSelectInputTwo,
} from "../Form/Form";
import "./product.css";
import ImageUploader from "./components/ImageUploader";

const SubTab = ({ id, name, index, image }) => {
  return (
    <li className={`nav-item ${index > 0 ? "mr-3" : ""}`}>
      <a
        className={`nav-link ${index === 0 ? "active" : ""}`}
        data-toggle="tab"
        href={`#${id}_${index}`}
      >
        <>
          {false && image && (
            <span className="symbol symbol-20 mr-3">
              <img src={`${API.PORT}/${image}`} alt="" />
            </span>
          )}
          <span className="nav-text">{name}</span>
        </>
      </a>
    </li>
  );
};

const SubInput = ({
  id,
  index,
  errors,
  register,
  required,
  InputFields,
  code,
}) => {
  return (
    <div
      className={`tab-pane ${index === 0 ? "active" : ""}`}
      id={`${id}_${index}`}
      role="tabpanel"
    >
      <RenderMultiLangInputFields
        InputFields={InputFields}
        errors={errors}
        register={register}
        required={required}
        code={code}
      />

      <div className="row"></div>
    </div>
  );
};

const Add = () => {
  const ckEditorRef = useRef();

  const { languages } = useSelector((state) => state.setting);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    clearErrors,
    setError,
    control,
    unregister,
  } = useForm();

  const [parentCategoryInput, setParentCategoryInput] = useState([]);

  const [categoryL2Input, setCategoryL2Input] = useState([]);
  const [categoryL3Input, setCategoryL3Input] = useState([]);
  const [colorsList, setColorsList] = useState([]);
  const [sizesList, setSizesList] = useState([]);
  const [featuresList, setFeaturesList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [coverImage, setCoverImage] = useState();
  const [images, setImages] = useState([]);
  const [showAddVarient, setShowAddVarient] = useState(false);
  const [inputVarients, setInputVariants] = useState([]);

  //Media

  const { response, request } = useRequest();
  const { request: getParentCategory, response: parentCategoryResponse } =
    useRequest();
  const { request: getCategoryLevel2, response: categoryLevel2Response } =
    useRequest();
  const { request: getCategoryLevel3, response: categoryLevel3Response } =
    useRequest();
  const { request: getActiveColors, response: activeColorsResponse } =
    useRequest();
  const { request: getActiveSizes, response: activeSizesResponse } =
    useRequest();
  const { request: getActiveFeatures, response: activeFeaturesResponse } =
    useRequest();
  const { request: getActiveBrands, response: activeBrandsResponse } =
    useRequest();

  useEffect(() => {
    document.title = "Add-Color - Single Vendor";
    getParentCategory("GET", `category/activeCategories?parent_id=${0}`);
    getActiveColors("GET", `color/active`);
    getActiveSizes("GET", `size/active`);
    getActiveFeatures("GET", `feature/active`);
    getActiveBrands("GET", `brand/active`);
  }, []);

  useEffect(() => {
    if (!activeColorsResponse) return;
    let colors = activeColorsResponse.colors.map((color) => {
      return { label: color.name, value: color.id };
    });
    setColorsList(colors);
  }, [activeColorsResponse]);

  useEffect(() => {
    if (!activeSizesResponse) return;
    let sizes = activeSizesResponse.sizes.map((size) => {
      return { label: size.name, value: size.id };
    });
    setSizesList(sizes);
  }, [activeSizesResponse]);

  useEffect(() => {
    if (!activeFeaturesResponse) return;
    let features = activeFeaturesResponse.features.map((feature) => {
      return { label: feature.name, value: feature.id };
    });
    setFeaturesList(features);
  }, [activeFeaturesResponse]);

  useEffect(() => {
    if (!activeBrandsResponse) return;

    setBrandList(activeBrandsResponse.brands);
  }, [activeBrandsResponse]);

  useEffect(() => {
    if (!parentCategoryResponse) return;
    setParentCategoryInput(parentCategoryResponse.categories);
  }, [parentCategoryResponse]);

  useEffect(() => {
    if (response) {
      toast.success(response.message);
      history.push("/product");
    }
  }, [response]);

  /* useEffect(() => {
    if (languages) {
      languages.forEach((lang, index) => {
        register(`item_description-${lang.code}`, { required: true });
        register(`service_description-${lang.code}`, { required: true });
        register(`exchange_and_refund_description-${lang.code}`, {
          required: true,
        });
      });
    }
  }, [languages]); */

  //Media

  const onSubmitNew = (data) => {
    console.log("data:::::::::::::", data);
    const formData = new FormData();
    formData.append("parent_category", data.parent_category);
    formData.append("category_level_2", data.category_level_2);
    formData.append("category_level_3", data.category_level_3);
    formData.append("brand_id", data.brand_id);
    formData.append("colors", JSON.stringify(data.colors));
    formData.append("features", JSON.stringify(data.features));
    formData.append("sizes", JSON.stringify(data.sizes));
    formData.append("price", data.price);
    formData.append("discount_price", data.discount_price || 0);
    formData.append("stock_qty", data.stock_qty);
    formData.append(
      "slug",
      `${data[`name-en`].replace(/ /g, "_")}-${Date.now()}`
    );
    formData.append("name", data["name-en"]);
    if (images.length === 0) {
      return toast.error("please upload image");
    }
    //console.log(coverImage);
    //console.log(images);
    formData.append("cover_image", coverImage);
    images.forEach((image, index) => {
      if (image != coverImage) {
        //console.log("im", image);
        formData.append("images", image);
      }
    });
    //console.log(formData.("images"));

    if (data["size_image"] && data["size_image"][0])
      formData.append("size_image", data["size_image"][0]);

    if (!data.variants.length) return toast.error("Please Add variants");

    if (data.variants)
      formData.append("variants", JSON.stringify(data.variants));
    const informationSubData = [];
    const descriptionSubData = [];

    for (let i = 0; i < languages.length; i++) {
      const id = languages[i].id;
      const code = languages[i].code;
      const slug = `${data[`name-${code}`].replace(/ /g, "_")}-${Date.now()}`;
      /* const infoDataEle = {
        language_id: id,
        lang_code: code,
        name: data[`name-${code}`],
        slug: slug,
      }; */
      const descDataEle = {
        language_id: id,
        lang_code: code,
        name: data[`name-${code}`],
        small_description: data[`small_description-${code}`],
        item_description: data[`item_description-${code}`] || "",
        service_description: data[`service_description-${code}`] || "",
        exchange_and_refund_description:
          data[`exchange_and_refund_description-${code}`] || "",
      };

      //informationSubData.push(infoDataEle);
      descriptionSubData.push(descDataEle);
    }
    formData.append("informationSubData", JSON.stringify(informationSubData));
    formData.append("descriptionSubData", JSON.stringify(descriptionSubData));

    request("POST", "product", formData);
  };

  function openTab(evt, tabName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " active";
  }

  const changeParentCategory = (value) => {
    //getCategoryLevel2()
    //console.log(value);
    getCategoryLevel2("GET", `category/activeCategories?parent_id=${value}`);
  };

  const changeCategoryL2 = (value) => {
    getCategoryLevel3("GET", `category/activeCategories?parent_id=${value}`);
  };

  useEffect(() => {
    if (!categoryLevel2Response) return;
    //console.log(categoryLevel2Response);
    setCategoryL2Input(categoryLevel2Response.categories);
    setCategoryL3Input([]);
    setValue("category_level_2", "");
    setValue("category_level_3", "");
  }, [categoryLevel2Response]);

  useEffect(() => {
    if (!categoryLevel3Response) return;
    //console.log(categoryLevel2Response);
    setCategoryL3Input(categoryLevel3Response.categories);
    setValue("category_level_3", "");
  }, [categoryLevel3Response]);

  const ProductInformationInputFields = [
    [
      {
        Component: Input,
        label: "Name",
        name: "name",
        registerFields: {
          required: true,
        },
        type: "text",
      },
    ],
  ];

  const ProductCommonInformationInputField = [
    {
      Component: SelectInput,
      label: "Parent Category",
      name: "parent_category",
      registerFields: {
        required: true,
      },
      type: "SelectInput",
      onChange: changeParentCategory,
      children: (
        <>
          <option value={""}>Select Parent Category</option>,
          {parentCategoryInput.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      Component: SelectInput,
      label: "Category Level 2",
      name: "category_level_2",
      registerFields: {
        required: true,
      },
      type: "SelectInput",
      onChange: changeCategoryL2,
      children: (
        <>
          <option value={""}>Select Category</option>,
          {categoryL2Input.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      Component: SelectInput,
      label: "Category Level 3",
      name: "category_level_3",
      registerFields: {
        required: true,
      },
      type: "SelectInput",
      children: (
        <>
          <option value={""}>Select Category</option>,
          {categoryL3Input.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      Component: SelectInput,
      label: "Brand",
      name: "brand_id",
      registerFields: {
        required: true,
      },
      type: "SelectInput",
      children: (
        <>
          <option value={""}>Select Brand</option>,
          {brandList.map((brand) => (
            <option value={brand.id}>{brand.name}</option>
          ))}
        </>
      ),
    },
    {
      Component: Input,
      label: "Size image",
      name: "size_image",
      registerFields: {
        required: false,
      },
      isRequired: false,
      inputData: {
        accept: "image/*",
      },
      type: "file",
    },
  ];

  const PriceInputFields = [
    {
      Component: Input,
      label: "Price",
      name: "price",
      registerFields: {
        required: true,
      },
      type: "number",
    },
    {
      Component: Input,
      label: "Discount Price",
      name: "discount_price",
      registerFields: {
        required: false,
      },
      type: "number",
    },
    {
      Component: Input,
      label: "Stock Quantity",
      name: "stock_qty",
      registerFields: {
        required: true,
      },
      type: "number",
    },
  ];

  const DescriptionInputFields = [
    [
      {
        Component: Textarea,
        label: "Small Description",
        name: "small_description",
        registerFields: {
          required: true,
        },
        type: "text",
        colClass: "col-xl-12",
      },
      {
        Component: CKEditorInput,
        colClass: "col-xl-12",
        label: "Item Description",
        name: "item_description",
        registerFields: {
          required: false,
        },
        isRequired: false,
        getValues,
        setValue,
        trigger,
        inputData: {
          onInstanceReady: (editor) => {
            ckEditorRef.current = editor;
          },
        },
        clearErrors,
        isEdit: false,
        setError,
      },
      {
        Component: CKEditorInput,
        colClass: "col-xl-12",
        label: "Service Description",
        name: "service_description",
        registerFields: {
          required: false,
        },
        isRequired: false,
        getValues,
        setValue,
        trigger,
        inputData: {
          onInstanceReady: (editor) => {
            ckEditorRef.current = editor;
          },
        },
        clearErrors,
        isEdit: false,
        setError,
      },
      {
        Component: CKEditorInput,
        colClass: "col-xl-12",
        label: "Exchange and Refund Description",
        name: "exchange_and_refund_description",
        registerFields: {
          required: false,
        },
        isRequired: false,
        getValues,
        setValue,
        trigger,
        inputData: {
          onInstanceReady: (editor) => {
            ckEditorRef.current = editor;
          },
        },
        clearErrors,
        isEdit: false,
        setError,
      },
    ],
  ];

  const FeaturesInputField = [
    {
      Component: ReactSelectInputTwo,
      label: "Feature",
      name: "features",
      registerFields: {
        required: true,
      },
      options: featuresList,
      isMultiple: true,
      control: control,
    },
  ];

  const VarientInputField = [
    {
      Component: ReactSelectInputTwo,
      label: "Color",
      name: "colors",
      registerFields: {
        required: true,
      },
      options: colorsList,
      isMultiple: true,
      control: control,
    },
    {
      Component: ReactSelectInputTwo,
      label: "Size",
      name: "sizes",
      registerFields: {
        required: true,
      },
      options: sizesList,
      isMultiple: true,
      control: control,
    },
  ];

  const makeVarient = () => {
    const selectedColors = getValues("colors");
    const selectedSizes = getValues("sizes");
    //console.log(selectedColors);
    //console.log(selectedSizes);
    //if (!selectedColors || !selectedColors.length) return;
    //if (!selectedSizes || !selectedSizes.length) return;
    const inputVarientList = [];

    selectedColors.forEach((color) => {
      selectedSizes.forEach((size) => {
        inputVarientList.push({
          label: `${color.label}-${size.label}`,
          colorId: color.value,
          sizeId: size.value,
          price: 0,
          discount_price: 0,
          stock_qty: 0,
        });
      });
    });
    //unregister("variants");
    setValue("variants", inputVarientList);
    setInputVariants(inputVarientList);
    setShowAddVarient(inputVarientList.length ? true : false);
  };

  useEffect(() => {
    document.getElementById("defaultOpen").click();
  }, []);

  //console.log("errors", errors, getValues());

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add-Product"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/product", name: "Product" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <form onSubmit={handleSubmit(onSubmitNew)}>
            <div className="card card-custom gutter-b">
              <div className="card-body flex">
                {/* <div className="row">
                  {COMMON.map((Input, index) => (
                    <Input.Component
                      key={index}
                      {...Input}
                      errors={errors}
                      register={register}
                      setValue={setValue}
                    />
                  ))}
                </div> */}

                <div className="tab">
                  <button
                    type="button"
                    className="tablinks"
                    onClick={(e) => openTab(e, "productInformation")}
                    id="defaultOpen"
                  >
                    Product Information
                  </button>
                  <button
                    className="tablinks"
                    type="button"
                    onClick={(e) => openTab(e, "prices")}
                  >
                    Prices
                  </button>
                  <button
                    className="tablinks"
                    type="button"
                    onClick={(e) => openTab(e, "productImages")}
                  >
                    Product Images
                  </button>
                  <button
                    className="tablinks"
                    type="button"
                    onClick={(e) => openTab(e, "productDescriptions")}
                  >
                    Product Descriptions
                  </button>
                  <button
                    className="tablinks"
                    type="button"
                    onClick={(e) => openTab(e, "productFeature")}
                  >
                    Product Features
                  </button>
                  <button
                    className="tablinks"
                    type="button"
                    onClick={(e) => openTab(e, "productVarient")}
                  >
                    Product Varient
                  </button>
                </div>

                <div id="productInformation" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">
                    Product Information
                  </h3>
                  <div className="card-header-tabs-line">
                    <div className="card-toolbar">
                      <ul
                        className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                        role="tablist"
                      >
                        {languages.length > 0 &&
                          languages.map((lang, index) => (
                            <SubTab
                              id="productInformation"
                              key={index}
                              name={lang.name}
                              index={index}
                              image={lang?.image}
                            />
                          ))}
                      </ul>
                    </div>
                  </div>
                  <div className="card-body px-0">
                    <div className="tab-content px-0">
                      {languages.length > 0 &&
                        languages.map((lang, index) => (
                          <>
                            <SubInput
                              id="productInformation"
                              key={index}
                              index={index}
                              errors={errors}
                              register={register}
                              required={true}
                              InputFields={ProductInformationInputFields}
                              code={lang.code}
                            />
                          </>
                        ))}
                    </div>
                    <button
                      onClick={handleSubmit(onSubmitNew)}
                      style={{ display: "none" }}
                    ></button>

                    <div className="row"></div>
                  </div>
                  <div className="row">
                    {ProductCommonInformationInputField.map((Input, index) => (
                      <Input.Component
                        key={index}
                        {...Input}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                      />
                    ))}
                  </div>
                </div>

                <div id="prices" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">
                    Price Information
                  </h3>
                  <div className="row">
                    {PriceInputFields.map((Input, index) => (
                      <Input.Component
                        key={index}
                        {...Input}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                      />
                    ))}
                  </div>
                </div>

                <div id="productImages" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">Images</h3>
                  <ImageUploader
                    images={images}
                    setImages={setImages}
                    setCoverImage={setCoverImage}
                    coverImage={coverImage}
                  />
                </div>
                <div id="productDescriptions" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">
                    Product Description
                  </h3>
                  <div className="card-header-tabs-line">
                    <div className="card-toolbar">
                      <ul
                        className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                        role="tablist"
                      >
                        {languages.length > 0 &&
                          languages.map((lang, index) => (
                            <SubTab
                              id="productDescriptions"
                              key={index}
                              name={lang.name}
                              index={index}
                              image={lang?.image}
                            />
                          ))}
                      </ul>
                    </div>
                  </div>
                  <div className="card-body px-0">
                    <div className="tab-content px-10">
                      {languages.length > 0 &&
                        languages.map((lang, index) => (
                          <>
                            <SubInput
                              id="productDescriptions"
                              key={index}
                              index={index}
                              errors={errors}
                              register={register}
                              required={true}
                              InputFields={DescriptionInputFields}
                              code={lang.code}
                            />
                          </>
                        ))}
                    </div>
                    <button
                      onClick={handleSubmit(onSubmitNew)}
                      style={{ display: "none" }}
                    ></button>

                    <div className="row"></div>
                  </div>
                </div>
                <div id="productFeature" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">
                    Product Features
                  </h3>
                  <div className="row">
                    {FeaturesInputField.map((Input, index) => (
                      <Input.Component
                        key={index}
                        {...Input}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                      />
                    ))}
                  </div>
                </div>
                <div id="productVarient" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">
                    Product Varients
                  </h3>
                  <div className="row">
                    {VarientInputField.map((Input, index) => (
                      <Input.Component
                        key={index}
                        {...Input}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                      />
                    ))}
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    id="variantbuttons"
                    onClick={makeVarient}
                  >
                    Make Variant
                  </button>
                  {showAddVarient && (
                    <div class="col-lg-12">
                      <div class="dataTables_wrapper">
                        <div class="table-responsive" id="varianttable">
                          <table class="table dataTable table-head-custom table-head-bg table-borderless table-vertical-center">
                            <thead>
                              <tr class="text-uppercase">
                                <th
                                  style={{ minWidth: "80px", maxWidth: "80px" }}
                                >
                                  <a href="javascript:void(0);">Variant</a>
                                </th>
                                <th
                                  style={{ minWidth: "80px", maxWidth: "80px" }}
                                >
                                  <a href="javascript:void(0);">Price</a>
                                </th>
                                <th
                                  style={{ minWidth: "80px", maxWidth: "80px" }}
                                >
                                  <a href="javascript:void(0);">
                                    Discount Price
                                  </a>
                                </th>
                                <th
                                  style={{ minWidth: "80px", maxWidth: "80px" }}
                                >
                                  <a href="javascript:void(0);">Stock QTY</a>
                                </th>
                                {/* <th style="width:40%;">
                                  <a href="javascript:void(0);">Images</a>
                                </th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {inputVarients.map((variant, index) => (
                                <tr>
                                  <td>
                                    {" "}
                                    <input
                                      {...register(`variants.${index}.colorId`)}
                                      value={variant.colorId}
                                      type="hidden"
                                    />{" "}
                                    <input
                                      {...register(`variants.${index}.sizeId`)}
                                      value={variant.sizeId}
                                      type="hidden"
                                    />{" "}
                                    {variant.label}
                                  </td>
                                  <td>
                                    <input
                                      class="form-control form-control-solid form-control-lg"
                                      {...register(`variants.${index}.price`)}
                                      type="number"
                                      defaultValue={variant.price}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      class="form-control form-control-solid form-control-lg"
                                      {...register(
                                        `variants.${index}.discount_price`
                                      )}
                                      type="number"
                                      defaultValue={variant.discount_price}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      class="form-control form-control-solid form-control-lg variant_stock_quantity"
                                      {...register(
                                        `variants.${index}.stock_qty`
                                      )}
                                      defaultValue={variant.stock_qty}
                                      type="number"
                                    />
                                  </td>
                                  <td>
                                    <div
                                      class="row variant_parent"
                                      style={{ flexWrap: "nowrap" }}
                                    ></div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <SubmitButton
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmitNew}
                  name="Add"
                  pxClass="px-10"
                />
              </div>
            </div>

            {/* <div className="card card-custom gutter-b">
              <div className="card-header card-header-tabs-line">
                <div className="card-toolbar">
                  <ul
                    className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                    role="tablist"
                  >
                    {languages.length > 0 &&
                      languages.map((lang, index) => (
                        <SubTab
                          key={index}
                          name={lang.name}
                          index={index}
                          image={lang?.image}
                        />
                      ))}
                  </ul>
                </div>
              </div>

              <div className="card-body px-0">
                <div className="tab-content px-10">
                  {languages.length > 0 &&
                    languages.map((lang, index) => (
                      <>
                        <SubInput
                          key={index}
                          index={index}
                          errors={errors}
                          register={register}
                          required={true}
                          InputFields={InputFields}
                          code={lang.code}
                        />
                      </>
                    ))}
                </div>
                <button
                  onClick={handleSubmit(onSubmitNew)}
                  style={{ display: "none" }}
                ></button>

                <SubmitButton
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmitNew}
                  name="Add"
                  pxClass="px-10"
                />

                <div className="row"></div>
              </div>
            </div> */}
          </form>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default Add;
